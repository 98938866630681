import "core-js/stable";
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

const Home = React.lazy(() => import("./pages/home/home"));
const Contact = React.lazy(() => import('./pages/contact'));

const AboutTeam = React.lazy(() => import("./pages/about/our-team"));
const AboutDr = React.lazy(() => import("./pages/about/dr-lawrence-brent"));

const AllOnFour = React.lazy(() => import("./pages/services/all-on-four"));
const BondingsAndFillings = React.lazy(() => import("./pages/services/bondings-and-fillings"));
const Bridges = React.lazy(() => import("./pages/services/bridges"));
const CrownsAndCaps = React.lazy(() => import("./pages/services/crowns-and-caps"));
const DentalImplants = React.lazy(() => import("./pages/services/dental-implants"));
const EmergDentistry = React.lazy(() => import("./pages/services/emergency-dentistry"));
const HygieneAndPeriodontal = React.lazy(() => import("./pages/services/hygiene-and-periodontal"));
const RootCanal = React.lazy(() => import("./pages/services/root-canal-therapy"));
const PreventiveDentistry = React.lazy(() => import("./pages/services/preventive-dentistry"));

const SureSmile = React.lazy(() => import("./pages/cosmetics/sure-smile"));
const TeethWhitening = React.lazy(() => import("./pages/cosmetics/teeth-whitening"));
const Veneers = React.lazy(() => import("./pages/cosmetics/veneers"));

const OralScanner = React.lazy(() => import("./pages/technologies/trios-oral-scanner"));
const AnesthesiaBuffering = React.lazy(() => import("./pages/technologies/anesthesia-buffering"));
const PearlAI = React.lazy(() => import("./pages/technologies/ai-dental-x-rays"));

const Blog = React.lazy(() => import('./pages/resources/blog'));
const CovidProtocol = React.lazy(() => import('./pages/resources/covid-protocols'));
const Directions = React.lazy(() => import('./pages/resources/directions'));
const Membership = React.lazy(() => import('./pages/resources/membershipplan'));
const NewAndExistingPatients = React.lazy(() => import('./pages/resources/new-existing-patients'));
const PatientForms = React.lazy(() => import('./pages/resources/patient-forms'));
const Reviews = React.lazy(() => import('./pages/resources/reviews'));
const VirtualTour = React.lazy(() => import('./pages/resources/virtual-tour'));

const PrivacyPolicy = React.lazy(() => import('./pages/resources/privacy-policy'));
const TermsOfUse = React.lazy(() => import('./pages/resources/terms-of-use'));


ReactDOM.render(
  <Router>
    <Suspense fallback={<Spinner animation="border" role="status" as="span" variant="primary"/>}> 
      <Switch>
        <Route exact path={"/"} component={Home} />

        <Route path={"/about/our-team"} component={AboutTeam} />
        <Route path={"/about/dr-lawrence-brent"} component={AboutDr} />

        <Route path={"/services/all-on-four"} component={AllOnFour} />   
        <Route path={"/services/bondings-and-fillings"} component={BondingsAndFillings} />
        <Route path={"/services/bridges"} component={Bridges} />
        <Route path={"/services/crowns-and-caps"} component={CrownsAndCaps} />
        <Route path={"/services/dental-implants"} component={DentalImplants} />   
        <Route path={"/services/emergency-dentistry"} component={EmergDentistry} />
        <Route path={"/services/hygiene-and-periodontal"} component={HygieneAndPeriodontal} />
        <Route path={"/services/root-canal-therapy"} component={RootCanal} />
        <Route path={"/services/preventive-dentistry"} component={PreventiveDentistry} />

        <Route path={"/cosmetics/sure-smile"} component={SureSmile} />   
        <Route path={"/cosmetics/teeth-whitening"} component={TeethWhitening} />      
        <Route path={"/cosmetics/veneers"} component={Veneers} />

        <Route path={"/technologies/novocaine-buffering"} component={AnesthesiaBuffering} />
        <Route path={"/technologies/trios-oral-scanner"} component={OralScanner} />   
        <Route path={"/technologies/ai-dental-x-rays"} component={PearlAI} />   

        <Route path={"/resources/blog"} component={Blog} />      
        <Route path={"/resources/covid-protocols"} component={CovidProtocol} />
        <Route path={"/resources/directions"} component={Directions} />
        <Route path={"/resources/patient-forms"} component={PatientForms} />
        <Route path={"/resources/membershipplan"} component={Membership} />
        <Route path={"/resources/new-existing-patients"} component={NewAndExistingPatients} />     
        <Route path={"/resources/reviews"} component={Reviews} />      
        <Route path={"/resources/virtual-tour"} component={VirtualTour} />          
        <Route path={"/resources/privacy-policy"} component={PrivacyPolicy} />      
        <Route path={"/resources/terms-of-use"} component={TermsOfUse} />   

        <Route path={"/contact"} component={Contact} />      
      </Switch>
    </Suspense>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//instead of linking to a page, link to a #id
